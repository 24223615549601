import React, { useEffect, useState } from "react";

import logo from "../../assets/logoblanc.svg";
import cloud from "../../assets/nuage.svg";
import background from "../../assets/background.svg";
import "./drawer.scss";

const SECTION_1 = "Episodes";
const SECTION_2 = "Les combattants";
const SECTION_3 = "Les capas spéciales";
const SECTION_4 = "Le Shokai";

const Drawer = () => {
  return <div className="drawerContainer">hey</div>;
};

export default Drawer;

import React from "react";
import { Trans } from "react-i18next";
import { useInView } from "react-intersection-observer";

import "./presentation.scss";

const Presentation = () => {
  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const { ref: ref3, inView: inView3 } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const { ref: ref4, inView: inView4 } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div className="main">
      <div className="section1 section">
        <h1 ref={ref1}>
          {inView1 && (
            <div className="textFromLeft">
              <Trans i18nKey="section1.title"></Trans>
            </div>
          )}
        </h1>
        <p>
          <Trans i18nKey="section1.paragraph1">
            <span style={{ fontWeight: 900 }}>fighting_game</span>
            <span style={{ fontWeight: 900 }}>zero_controllers</span>
          </Trans>
          <br />
          <br />
          <Trans i18nKey="section1.paragraph2">
            <span style={{ fontWeight: 900 }}>epic_battles</span>
            <span style={{ fontWeight: 900 }}>real_skills</span>
            <span style={{ fontWeight: 900 }}> super_powers</span>
          </Trans>
          <br />
          <br />
          <Trans i18nKey="section1.paragraph3"></Trans>
          <br />
          <br />
          <Trans i18nKey="section1.paragraph4"></Trans>
          <br />
          <br />
          <Trans i18nKey="section1.paragraph5"></Trans>
        </p>
      </div>
      <div className="section2 section">
        <h1 ref={ref2}>
          {inView2 && (
            <div className="textFromRight">
              <Trans i18nKey="section2.title"></Trans>
            </div>
          )}
        </h1>
        <p>
          <Trans i18nKey="section2.paragraph1"></Trans>
          <br />
          <br />
          <Trans i18nKey="section2.paragraph2"></Trans>
        </p>
      </div>
      <div className="section1 section sectionRow">
        <h1 ref={ref3} style={{ marginBottom: 20 }}>
          {inView3 && (
            <div className="textFromRight">
              <Trans i18nKey="section3.title"></Trans>
            </div>
          )}
        </h1>
        <div className="subRow">
          <div className="nameCard">
            <img src="./photo_johann.jpeg" alt="" />
            <div className="firstname">Johann</div>
            {/* <div className="position">C.E.O</div> */}
            <div className="roleDescription">
              <Trans i18nKey="section3.johann_description"></Trans>
            </div>
          </div>
          <div className="nameCard">
            <img src="./photo_mick.png" alt="" />
            <div className="firstname">Mick</div>
            {/* <div className="position">C.T.O</div> */}
            <div className="roleDescription">
              <Trans i18nKey="section3.alexandre_description"></Trans>
            </div>
          </div>
        </div>
        <div className="subRow">
          <div className="nameCard" style={{ width: "100%" }}>
            <div className="roleDescription">
              <Trans i18nKey="section3.partnership_label">
                in_partnership_with <br />
                research_lab
              </Trans>
            </div>
            <img src="./logo_inria.png" alt="" />
          </div>
        </div>
      </div>
      <div className="section2 section">
        <h1 ref={ref4}>
          {inView4 && (
            <div className="textFromLeft">
              <Trans i18nKey="section4.title"></Trans>
            </div>
          )}
        </h1>
        <p>
          <Trans i18nKey="section4.paragraph1"></Trans>
          <br />
          <br />
          <Trans i18nKey="section4.contact_information">
            introduction_part <br />
            <span style={{ fontWeight: "bold" }}>
              {{ email: "contact@shotam.app" }}
            </span>
          </Trans>
        </p>
      </div>
      <div className="section">
        <h1 className="textFromLeft part">
          <Trans i18nKey="section5.title" ></Trans>
        </h1>
        <div className="subRow">
          <div className="nameCard">
            <img src="./cmjosny_logo.jpg" alt="" />
            <div className="partnerName">La CMJ d'Osny</div>
            <div className="verbatim"></div>
          </div>
          <div className="nameCard">
            <img src="./cumg_logo.png" alt="" />
            <div className="partnerName">Com' Unity Media group</div>
            <div className="verbatim">"Nous avons fait appel à Shotam lors de notre événement Mang'A'Bernes et leur animation VR était superbe ! Petits et grands se sont prêté au jeu et ont appréciés s'affronter dans leur arène virtuelle. Un bon divertissement familial !"</div>
          </div>
          <div className="nameCard">
            <img src="./cumg_logo.png" alt="" />
            <div className="partnerName">Alexandre D.</div>
            <div className="verbatim">"Shotam est sans doute le jeu qui vous rappelle les animés avec lesquels vous avez grandi. Une arène virtuelle dans laquelle on peut affronter ses amis ou sa famille en se prenant pour l'un de ses plus grands héros de manga !"</div>
          </div>
          <div className="nameCard">
            <img src="./RetroGeekFestival_logo.png" alt="" />
            <div className="partnerName">Retro Geek Festival</div>
          </div>
          <div className="nameCard">
            <img src="./ExpoMangaLagny_logo.png" alt="" />
            <div className="partnerName">Expo Manga Lagny</div>
          </div>
          <div className="nameCard">
            <img src="./epita_Logo.png" alt="" />
            <div className="partnerName">Epitanime</div>
          </div>
        </div>
      </div>
      {/* <div className="cardContainer">
        <div className="cardWrapper">
          <div className="card">
            <div className="cardContent">
              <span>
                Pour entrer dans l'univers Shotam, ce sera (bientôt) par ici :
              </span>
              <button disabled>Entrer</button>
            </div>
          </div>
        </div>
        <div className="cardWrapper">
          <div className="card">
            <div className="cardContent">
              <span>
                Et tous les secrets de conception de Shotam et de son univers :
                <small>(seront révélés dès l'épisode 1)</small>
              </span>
              <button disabled>Le SecretBook</button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Presentation;

import React from "react";

import Header from "./components/header/header";
import Presentation from "./components/presentation/presentation";
import Drawer from "./components/drawer/drawer";
import { selectIsDrawerOpen } from "./app/navigationSlice";

import "./App.scss";
import { useAppSelector } from "./app/hooks";

const App = () => {
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);

  return (
    <div className="App">
      <Header />
      {isDrawerOpen && <Drawer />}
      <Presentation />
    </div>
  );
};

export default App;

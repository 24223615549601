import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import logo from "../../assets/logoblanc.svg";
import cloud from "../../assets/nuage.svg";
import background from "../../assets/background.svg";
import { ReactComponent as SpinnerSvg } from "../../assets/spinner.svg";
import introVideo from "../../assets/intro_video2.mp4";

import "./header.scss";
import { selectIsDrawerOpen, toggleDrawer } from "../../app/navigationSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const MESSAGE = ``;

const TITLE = "Shotam";
// const SUBTITLE = `Les nouveaux héros`;
const CAPTION = "Coming soon";

const Header = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { t, i18n } = useTranslation();

  const scrollHandler = (event: Event) => {
    const containerHeight = window?.innerHeight;
    const scrollHeight = window?.scrollY;
    const visibility = 1 - Math.min(scrollHeight / containerHeight, 1);
    if (visibility > 0) {
      setVisibilityRateFirstView(visibility);
    }
  };

  const [subtitle, setSubtitle] = useState(t("header.title"));
  const [subtitleAnim, setSubtitleAnim] = useState<NodeJS.Timeout>();

  const [introEnded, setIntroEnded] = useState(false);
  const [visibilityRateFirstView, setVisibilityRateFirstView] = useState(1);

  const handleToggleDrawer = () => {
    if (isDrawerOpen) {
      const drawer = document.getElementById("drawer");
      drawer?.animate([{ height: "300px" }, { height: "0px" }], {
        duration: "300ms",
      });
    } else dispatch(toggleDrawer());
  };

  useEffect(() => {
    videoRef.current?.addEventListener("playing", () => {
      setTimeout(() => {
        setIntroEnded(true);
      }, 3000);
      setTimeout(() => {
        if (window.scrollY === 0) {
          window.scrollTo({
            top: window.innerHeight,
            behavior: "smooth",
          });
        }
      }, 4500);
    });
    // setTimeout(() => setIntroEnded(true), 5000);
    // window.addEventListener("scroll", scrollHandler);
    // return () => {
    //   window.removeEventListener("scroll", scrollHandler);
    // };
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    //   const sub = setInterval(() => {
    //     if (subtitle.length < SUBTITLE.length && introEnded) {
    //       setSubtitle((oldSubtitle) =>
    //         SUBTITLE.slice(0, oldSubtitle.length + 1)
    //       );
    //     } else if (subtitleAnim) {
    //       clearInterval(subtitleAnim);
    //     }
    //   }, 50);
    //   if (!subtitleAnim) setSubtitleAnim(sub);
    // }, 1000);
  }, [introEnded]);

  const getMainBackStyle = (rate: number) => {
    const speeding = 5;
    const newRate = Math.pow(rate, speeding);
    return {
      opacity: newRate,
      transform: `translateY(${150 * (1 - newRate)}px)`,
    };
  };

  const getLogoStyle = (rate: number) => {
    const threshold = 0.4;
    const isFixed = rate < threshold;
    const position: "fixed" | "relative" = isFixed ? "fixed" : "relative";
    return {
      position,
      flexGrow: isFixed ? 1 : 0,
      top: `${0 - 10 * rate}px`,
      opacity: isFixed ? 1 - rate / threshold + 0.3 : 1,
      padding: isFixed ? "10px" : "0px",
      width: isFixed ? "100%" : "auto",
      backgroundColor: isFixed ? "rgba(10, 0, 37, 1)" : "rgba(10, 0, 37, 0)",
    };
  };

  return (
    <>
      {/* <img className="cloud" src={cloud} width="300px" height="300px" alt="" /> */}
      <header className="App-header">
        <div className="loadingContainer">
          <SpinnerSvg />
        </div>
        {introEnded && (
          <div className="textBlock">
            <div className="logo" style={getLogoStyle(visibilityRateFirstView)}>
              <img
                src={logo}
                alt=""
                width="30px"
                height="30px"
                className="logoImage"
                onClick={handleToggleDrawer}
              />
            </div>
            <div className="title">{TITLE}</div>
            <div className="subtitle">
              {subtitle?.length > 0 ? subtitle : "_"}
            </div>
            {/* <div className="caption">{CAPTION}</div> */}
          </div>
        )}
        {/* {introEnded && ( */}
        <video autoPlay muted className="videoContainer" ref={videoRef}>
          <source
            type="video/mp4"
            src={introVideo}
            width="100%"
            height="100%"
          />
        </video>
      </header>
    </>
  );
};

export default Header;
